<template>
  <div class="company-info">
    <div class="i-title">公司资料</div>
    <div style="color:red;margin-bottom:20px">
      {{ title }}
    </div>
    <el-form :model="companyForm" label-width="100px" size="medium" :rules="companyFormRules" ref="companyFormRef"
      :disabled="isDisabledForm">
      <el-form-item label="公司Logo：">
        <el-upload class="cert-uploader" :show-file-list="false" action="/school/company/getUpload"
          :before-upload="beforeUpload" :on-success="logoUpload" name="upfile">
          <img v-if="companyForm.image" :src="companyForm.image" class="cert" />
          <i v-else class="el-icon-plus cert-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="公司简称：" prop="company_abbreviation">
        <el-input v-model="companyForm.company_abbreviation"></el-input>
      </el-form-item>
      <el-form-item label="公司全称：" prop="company_full">
        <el-input v-model="companyForm.company_full"></el-input>
      </el-form-item>
      <el-form-item label="所属行业：" prop="industry">
        <el-input v-model="companyForm.industry"></el-input>
      </el-form-item>
      <!-- <el-form-item label="融资阶段：" prop="financing_stage">
        <el-input v-model="companyForm.financing_stage"></el-input>
      </el-form-item> -->
      <el-form-item label="人员规模：" prop="num_scale">
        <el-input v-model="companyForm.num_scale"></el-input>
      </el-form-item>
      <el-form-item label="联系方式：" prop="company_phone">
        <el-input v-model="companyForm.company_phone"></el-input>
      </el-form-item>
      <el-form-item label="营业执照：" prop="license">
        <el-upload class="cert-uploader" :show-file-list="false" action="/school/company/getUpload"
          :before-upload="beforeUpload" :on-success="licenseUpload" name="upfile">
          <img v-if="companyForm.license" :src="companyForm.license" class="cert" />
          <i v-else class="el-icon-plus cert-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="公司简介：" prop="company_profile">
        <el-input type="textarea" :rows="8" placeholder="请填写公司介绍（最少10个字）" v-model="companyForm.company_profile"
          maxlength="1000" show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" size="medium" @click="sumbitCompanyInfo()">提交审核</el-button>
        <el-form style="float:left;margin-right: 20px;">
          <el-form-item>
            <el-button type="info" size="medium" v-if="isEditBtn" @click="handleEdit()">修改</el-button>
          </el-form-item>
        </el-form>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import {
    sumbitCompanyInfoApi,
    showCompanyInfoApi
  } from '@/api/user'
  export default {
    name: "companyInfo",
    data() {
      var phone = (rule, value, callback) => {
        let mobile = /^1[3|4|5|7|8][0-9]\d{8}$/; //手机
        let tel = /^(\d{3,4}-)?\d{7,8}$/; //座机
        if (!mobile.test(this.companyForm.company_phone) && !tel.test(this.companyForm.company_phone)) {
          callback(new Error('请输入正确合规的手机号码或座机号码'))
        } else {
          callback()
        }
      }
      return {
        companyForm: {
          image: "",
          company_abbreviation: "",
          company_full: "",
          industry: "",
          num_scale: "",
          company_profile: "",
          company_phone: '',
          license: ''
        },
        isDisabledForm: false,
        title: '',
        isEditBtn: true,
        companyFormRules: {
          company_abbreviation: [{
            required: true,
            message: '请输入公司简称',
            trigger: 'blur'
          }],
          company_full: [{
            required: true,
            message: '请输入公司全称',
            trigger: 'blur'
          }],
          industry: [{
            required: true,
            message: '请输入所属行业',
            trigger: 'blur'
          }],
          //   financing_stage: [{
          //     required: true,
          //     message: '请输入融资阶段',
          //     trigger: 'blur'
          //   }],
          num_scale: [{
            required: true,
            message: '请输入人员规模',
            trigger: 'blur'
          }],
          company_profile: [{
            required: true,
            message: '请输入公司简介',
            trigger: 'blur'
          }],
          company_phone: [{
            required: true,
            validator: phone,
            trigger: 'blur'
          }],
          license: [{
            required: true,
            message: '请上传营业执照',
            trigger: 'blur'
          }],
        }
      }
    },
    created() {
      this.showCompanyInfoList()
    },
    methods: {
      //  保存图片url
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg' ? true : file.type === 'image/png' ? true : false;
        const isLt2M = file.size / 1024 / 1024 <= 1;
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG或PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 1MB!');
        }
        return isJPG && isLt2M;
      },
      //上传logo图片
      logoUpload(res) {
        this.companyForm.image = res.result.fileName[0]
        this.$forceUpdate()
      },
      //上传营业执照图片
      licenseUpload(res) {
        this.companyForm.license = res.result.fileName[0]
        this.$forceUpdate()
      },
      //保存公司信息
      sumbitCompanyInfo() {
        this.$refs.companyFormRef.validate(async (valid) => {
          if (!valid) return;
          //  添加功能
          const {
            data: res
          } = await sumbitCompanyInfoApi(this.companyForm)
          if (res.status != 10000) {
           return this.$message.error(res.message)
          }
          this.$message.success('提交成功')
          this.showCompanyInfoList()
        })
      },
      //公司信息初始化
      async showCompanyInfoList() {
        const {
          data: res
        } = await showCompanyInfoApi()
        if (Array.prototype.isPrototypeOf(res.result)) {
          this.companyForm = {}
          this.isEditBtn = false
        } else {
          this.companyForm = res.result
          if (this.companyForm.status == 0) {
            this.title = '资料审核中...'
            this.isDisabledForm = true
            this.isEditBtn = false
          } else if (this.companyForm.status == 1) {
            this.title = '资料审核通过...'
            this.isDisabledForm = true
          } else {
            this.title = '资料审核未通过,需修改后重新提交。'
            this.isDisabledForm = false
            this.isEditBtn = false
          }
        }
      },
      handleEdit() {
        this.isDisabledForm = false
      }
    }
  }

</script>

<style lang="less" scoped>
  .company-info {
    .i-title {
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      border-bottom: 1px solid #e4e7ed;
      text-align: center;
    }

    .cert-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 120px;

      .cert-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        min-height: 120px;
        line-height: 120px;
        text-align: center;
      }

      .cert {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }

    .cert-uploader:hover {
      border-color: #409eff;
    }

    /deep/ .el-tabs__content {
      margin-top: 30px;
    }

    .el-input {
      width: 60%;
    }

    /deep/ .el-textarea__inner {
      width: 70%;
    }

    /deep/ .el-input__count {
      bottom: 8%;
      right: 32%;
    }

    .el-textarea {
      width: 86%;
    }
  }

</style>
